import {
  SELECT_ORG_SETTINGS_TAB,
  FETCH_CUSTOM_FIELDS,
  FETCH_ORGANIZATION_INFO,
  UPDATE_ORGANIZATION_DATA,
  FETCH_ROLE_SETTINGS,
  UPDATE_ROLE_SETTINGS,
  CHANGE_USER_ROLE,
  ADD_MESSAGE_RECIPIENT,
  REMOVE_MESSAGE_RECIPIENT,
  FETCH_CLIENT_USER_HEADER,
  UPDATE_ORGANIZATION_USERS,
  FETCH_AUTHORIZED_PROGRAM_SETTINGS,
  UPDATE_AUTHORIZED_PROGRAM_SETTINGS,
  RESEND_TRAVELER_INVITE,
  UPDATE_ADMIN_ACCOUNT,
  UPDATE_MESSAGE_RECIPIENTS,
  RESET_USERS_CODE,
  RESEND_ADMIN_INVITE,
  CANCEL_ADMIN_INVITE,
  CLEAR_ORGANIZATION_FLASH_MESSAGE,
  ARCHIVE_ADMIN,
  RESET_ARCHIVE_MESSAGE,
  INVITE_ORGANIZATION_ADMIN,
  RESET_ADMIN_INVITE_MESSAGE,
  CANCEL_TRAVELER_INVITE,
  RESET_TRAVELER_INVITE_MESSAGE,
  FETCH_MAILER_NOTIFICATIONS,
  FETCH_MAILER_NOTIFICATIONS_OPT_OUTS,
  UPDATE_MAILER_OPT_OUT_REQUEST,
  UPDATE_MAILER_OPT_OUT_SUCCESS,
  UPDATE_MAILER_OPT_OUT_CLEAR,
  UPDATE_MAILER_OPT_OUT_FAILURE,
  ORG_DETAILS,
  UPDATE_ORGANIZATION_DATA_SAFECHECK_TEXT,
  UPDATE_ORGANIZATION_DATA_REQUEST,
  UPDATE_ORGANIZATION_DATA_SUCCESS,
  UPDATE_ORGANIZATION_DATA_CLEAR,
  UPDATE_ORGANIZATION_DATA_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_CLEAR,
  UPDATE_ADMIN_ACCOUNT_REQUEST,
  UPDATE_ADMIN_ACCOUNT_SUCCESS,
  UPDATE_ADMIN_ACCOUNT_FAILURE,
  UPDATE_ADMIN_ACCOUNT_CLEAR,
  REMOVE_TRANSFER_ADMIN_REQUEST,
  REMOVE_TRANSFER_ADMIN_CLEAR,
  REMOVE_TRANSFER_ADMIN_SUCCESS,
  REMOVE_TRANSFER_ADMIN_FAILURE,
  FETCH_CUSTOM_FIELDS_REQUEST,
  FETCH_CUSTOM_FIELDS_CLEAR,
  FETCH_CUSTOM_FIELDS_SUCCESS,
  FETCH_CUSTOM_FIELDS_FAILURE,
  UPDATE_CUSTOM_FIELDS_REQUEST,
  UPDATE_CUSTOM_FIELDS_SUCCESS,
  UPDATE_CUSTOM_FIELDS_FAILURE,
  UPDATE_CUSTOM_FIELDS_CLEAR,
  ADD_CUSTOM_FIELDS_CLEAR,
  ADD_CUSTOM_FIELDS_REQUEST,
  ADD_CUSTOM_FIELDS_SUCCESS,
  ADD_CUSTOM_FIELDS_FAILURE,
  FETCH_ORG_INTAKE_QUESTIONS_REQUEST,
  FETCH_ORG_INTAKE_QUESTIONS_SUCCESS,
  FETCH_ORG_INTAKE_QUESTIONS_FAILURE,
  UPDATE_ORG_INTAKE_QUESTIONS_REQUEST,
  UPDATE_ORG_INTAKE_QUESTIONS_SUCCESS,
  UPDATE_ORG_INTAKE_QUESTIONS_FAILURE
} from '../actions/types';
import { getContrastColor } from '../shared/helpers/General';

const initialRequestToWithdrawConfig = {
  active: false,
  instruction: '',
  application_statuses: ['committed'],
};

const initialState = {
  activeTab: 'organization',
  custom_fieldsLoading: true,
  role_settingsLoading: true,
  userInfoLoading: true,
  authorized_program_settingsLoading: true,
  loading: true,
  custom_fields: [],
  organization: {},
  address: {},
  request_to_withdraw_setting: {
    active: false,
    instruction: '',
    application_statuses: ['committed'],
  },
  org_name: '',
  admins: [],
  travelers: [],
  role_settings: {},
  authorized_program_settings: {},
  userRoles: [],
  recipients: [],
  userInfo: {},
  updateUsersCode: null,
  updateUsersErrorMessage: null,
  updateMessageRecipientsCode: null,
  resendTravelerCode: null,
  cancelInviteCode: null,
  statusMessage: null,
  organizationSuccessMessage: '',
  updatedRolesSuccessMessage: '',
  archiveMessage: '',
  archiveMessageType: '',
  resendAdminCode: null,
  inviteAdminMessage: '',
  inviteAdminMessageType: '',
  travelerInviteCode: '',
  mailerOptions: [],
  mailersArchived: [],
  mailerOptOuts: {
    data: null,
    loading: false,
    error: null,
  },
  orgDetailsPublic: {
    logo: null,
    accentColor: null,
    accentTextColor: null,
    darkColor: null,
    darkTextColor: null,
    lightColor: null,
    lightTextColor: null,
    alias: null,
  },
  updated: null,
  updatedActionPayload: null,
  updateOrganizationData: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  changePassword: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  adminAccount: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  removeTransferAdmin: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  customFields: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  updateCustomFields: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  addCustomField: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  intakeQuestions: {
    data: [],
    showIntake: false,
    loading: false,
    error: ""
  },
  
  intakeQuestionsUpdate: {
    loading: false,
    error: "",
    data: null
  }

};

export default function(state = initialState, action) {
  let prevTravelers = [];

  switch (action.type) {
    case ORG_DETAILS:
      return {
        ...state,
        orgDetailsPublic: {
          logo: action.payload.logo,
          accentColor: action.payload.accentColor,
          accentTextColor: action.payload.accentTextColor,
          darkColor: action.payload.darkColor,
          darkTextColor: action.payload.darkTextColor,
          lightColor: action.payload.lightColor,
          lightTextColor: action.payload.lightTextColor,
          alias: action.payload.alias,
        },
      };
    case SELECT_ORG_SETTINGS_TAB:
      return {
        ...state,
        activeTab: action.tab,
      };
    case FETCH_CUSTOM_FIELDS:
      return {
        ...state,
        custom_fields: action.payload,
        custom_fieldsLoading: false,
        updated: action.updated,
      };
    case FETCH_ROLE_SETTINGS:
      return {
        ...state,
        role_settings: action.payload,
        role_settingsLoading: false,
      };
    case UPDATE_ROLE_SETTINGS:
      return {
        ...state,
        role_settings: action.payload,
        role_settingsLoading: false,
        updatedRolesSuccessMessage: action.payload.success,
      };
    case FETCH_ORGANIZATION_INFO:
      return {
        ...state,
        address: action.payload.address,
        request_to_withdraw_setting: action?.payload?.request_to_withdraw_setting || initialRequestToWithdrawConfig,
        organization: action.payload.client_account,
        loading: false,
        org_name: action.payload.org_name,
        admins: action.payload.admins,
        travelers: action.payload.travelers,
        recipients: action.payload.admins
          ? action.payload.admins.filter(admin => admin.recipient).map(recipient => recipient.id)
          : [],
      };
    case CHANGE_USER_ROLE:
      return {
        ...state,
        userRoles: [...state.userRoles, action.userRole],
      };
    case ADD_MESSAGE_RECIPIENT:
      return {
        ...state,
        recipients: [...state.recipients, action.id],
      };
    case REMOVE_MESSAGE_RECIPIENT:
      return {
        ...state,
        recipients: [...state.recipients.filter(recipient => recipient != action.id)],
      };
    case FETCH_CLIENT_USER_HEADER:
      return {
        ...state,
        userInfo: action.payload,
        userInfoLoading: false,
      };
    case UPDATE_ORGANIZATION_USERS:
      return {
        ...state,
        updateUsersCode: action.code,
        updateUsersErrorMessage: action.payload.error ? action.payload.error[0] : null,
      };
    case UPDATE_MESSAGE_RECIPIENTS:
      return {
        ...state,
        updateMessageRecipientsCode: action.code,
      };
    case RESEND_TRAVELER_INVITE:
      return {
        ...state,
        resendTravelerCode: action.code,
      };
    case UPDATE_ADMIN_ACCOUNT:
      return {
        ...state,
        userInfo: action.payload,
        statusMessage: action.payload.status,
      };
    case RESEND_ADMIN_INVITE:
      return {
        ...state,
        resendAdminCode: action.code,
      };
    case CANCEL_ADMIN_INVITE:
      const { user_id } = action;

      return {
        ...state,
        cancelInviteCode: action.code,
        admins: filterAdmins({ ...state }.admins, user_id != undefined, user_id),
      };

    case FETCH_AUTHORIZED_PROGRAM_SETTINGS:
      return {
        ...state,
        authorized_program_settings: action.payload,
        authorized_program_settingsLoading: false,
        statusMessage: action.payload.status,
      };
    case UPDATE_AUTHORIZED_PROGRAM_SETTINGS:
      return {
        ...state,
        authorized_program_settings: action.payload,
        authorized_program_settingsLoading: false,
        statusMessage: action.payload.status,
      };
    case RESET_USERS_CODE:
      return {
        ...state,
        resendTravelerCode: null,
        updateUsersCode: null,
        updateUsersErrorMessage: null,
        updateMessageRecipientsCode: null,
        cancelInviteCode: null,
        resendAdminCode: null,
      };
    case UPDATE_ORGANIZATION_DATA:
      return {
        ...state,
        organizationSuccessMessage: action.payload.success,
        updatedActionPayload: action.payload,
      };
    case UPDATE_ORGANIZATION_DATA_SAFECHECK_TEXT:
      return {
        ...state,
        organizationSuccessMessage: action.payload.success,
        organization: {
          safe_check_custom_text: action.payload.data,
        },
      };
    case CLEAR_ORGANIZATION_FLASH_MESSAGE:
      return {
        ...state,
        organizationSuccessMessage: '',
        updatedRolesSuccessMessage: '',
        statusMessage: null,
      };
    case ARCHIVE_ADMIN:
      const archiveMessageType = action.payload.type;
      return Object.assign(
        { ...state },
        {
          admins: filterAdmins({ ...state }.admins, archiveMessageType === 'success', action.payload.user_id),
          archiveMessage: action.payload.message,
          archiveMessageType: archiveMessageType,
        },
      );

    case RESET_ARCHIVE_MESSAGE:
      return {
        ...state,
        archiveMessage: '',
        archiveMessageType: '',
      };
    case INVITE_ORGANIZATION_ADMIN:
      const { user } = action.payload;
      return {
        ...state,
        admins: user != undefined ? [...state.admins, user] : { ...state }.admins,
        inviteAdminMessage: action.payload.message,
        inviteAdminMessageType: action.payload.type,
      };
    case RESET_ADMIN_INVITE_MESSAGE:
      return {
        ...state,
        inviteAdminMessage: '',
        inviteAdminMessageType: '',
      };
    case CANCEL_TRAVELER_INVITE:
      prevTravelers =
        action.payload.code === 200
          ? [...state.travelers.filter(x => x.email != action.payload.user_email)]
          : [...state.travelers];
      return {
        ...state,
        travelers: prevTravelers,
        travelerInviteCode: action.payload.code,
      };
    case RESET_TRAVELER_INVITE_MESSAGE:
      return {
        ...state,
        travelerInviteCode: '',
      };
    case FETCH_MAILER_NOTIFICATIONS:
      return {
        ...state,
        mailerOptions: action.payload.data,
      };
    case FETCH_MAILER_NOTIFICATIONS_OPT_OUTS:
      return {
        ...state,
        mailersArchived: action.payload.data,
      };
    case UPDATE_MAILER_OPT_OUT_REQUEST:
      return {
        ...state,
        mailerOptOuts: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case UPDATE_MAILER_OPT_OUT_SUCCESS:
      return {
        ...state,
        mailerOptOuts: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case UPDATE_MAILER_OPT_OUT_FAILURE:
      return {
        ...state,
        mailerOptOuts: {
          data: null,
          loading: false,
          error: 'There was a problem opting out of the mailer. Please try again later',
        },
      };
    case UPDATE_MAILER_OPT_OUT_CLEAR:
      return {
        ...state,
        mailerOptOuts: {
          data: null,
          loading: false,
          error: null,
        },
      };

    case UPDATE_ORGANIZATION_DATA_CLEAR:
      return {
        ...state,
        updateOrganizationData: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case UPDATE_ORGANIZATION_DATA_REQUEST:
      return {
        ...state,
        updateOrganizationData: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case UPDATE_ORGANIZATION_DATA_SUCCESS:
      return {
        ...state,
        updateOrganizationData: {
          data: action.payload,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case UPDATE_ORGANIZATION_DATA_FAILURE:
      return {
        ...state,
        updateOrganizationData: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePassword: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          data: action.payload,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePassword: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case CHANGE_PASSWORD_CLEAR:
      return {
        ...state,
        changePassword: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case UPDATE_ADMIN_ACCOUNT_REQUEST:
      return {
        ...state,
        adminAccount: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case UPDATE_ADMIN_ACCOUNT_SUCCESS:
      return {
        ...state,
        adminAccount: {
          data: action.payload,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case UPDATE_ADMIN_ACCOUNT_FAILURE:
      return {
        ...state,
        adminAccount: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case UPDATE_ADMIN_ACCOUNT_CLEAR:
      return {
        ...state,
        adminAccount: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };

    case REMOVE_TRANSFER_ADMIN_REQUEST:
      return {
        ...state,
        removeTransferAdmin: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case REMOVE_TRANSFER_ADMIN_SUCCESS:
      return {
        ...state,
        removeTransferAdmin: {
          data: action.payload,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case REMOVE_TRANSFER_ADMIN_FAILURE:
      return {
        ...state,
        removeTransferAdmin: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case REMOVE_TRANSFER_ADMIN_CLEAR:
      return {
        ...state,
        removeTransferAdmin: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case FETCH_CUSTOM_FIELDS_REQUEST:
      return {
        ...state,
        customFields: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case FETCH_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        customFields: {
          data: action.payload,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case FETCH_CUSTOM_FIELDS_FAILURE:
      return {
        ...state,
        customFields: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case FETCH_CUSTOM_FIELDS_CLEAR:
      return {
        ...state,
        customFields: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case UPDATE_CUSTOM_FIELDS_REQUEST:
      return {
        ...state,
        updateCustomFields: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case UPDATE_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        updateCustomFields: {
          data: action.payload,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case UPDATE_CUSTOM_FIELDS_FAILURE:
      return {
        ...state,
        updateCustomFields: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case UPDATE_CUSTOM_FIELDS_CLEAR:
      return {
        ...state,
        updateCustomFields: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case ADD_CUSTOM_FIELDS_REQUEST:
      return {
        ...state,
        addCustomField: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case ADD_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        addCustomField: {
          data: action.payload,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case ADD_CUSTOM_FIELDS_FAILURE:
      return {
        ...state,
        addCustomField: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case ADD_CUSTOM_FIELDS_CLEAR:
      return {
        ...state,
        addCustomField: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };

    case FETCH_ORG_INTAKE_QUESTIONS_REQUEST:
      return {
        ...state,
        intakeQuestions: {
          loading: true,
          showIntake: false,
          data: [],
          error: ""
        }
      }

    case FETCH_ORG_INTAKE_QUESTIONS_SUCCESS:
      return {
        ...state,
        intakeQuestions: {
          loading: false,
          showIntake: action.payload.show_intake,
          data: action.payload.intake_questions,
          error: ""
        }
      }

    case FETCH_ORG_INTAKE_QUESTIONS_FAILURE:
      return {
        ...state,
        intakeQuestions: {
          loading: false,
          showIntake: false,
          data: [],
          error: action.payload
        }
      }

    case UPDATE_ORG_INTAKE_QUESTIONS_REQUEST:
      return {
        ...state,
        intakeQuestionsUpdate: {
          loading: true,
          data: null,
          error: ""
        }
      }

    case UPDATE_ORG_INTAKE_QUESTIONS_SUCCESS:
      return {
        ...state,
        intakeQuestionsUpdate: {
          loading: false,
          data: action.payload,
          error: ""
        }
      }

    case UPDATE_ORG_INTAKE_QUESTIONS_FAILURE:
      return {
        ...state,
        intakeQuestionsUpdate: {
          loading: false,
          data: null,
          error: action.payload
        }
      }

    default:
      return state;
  }
}

const filterAdmins = (admins, condition, user_id) => {
  if (condition) {
    return admins.filter(admin => admin.id != user_id);
  }
  return admins;
};
